<template>
  <section class="currentlyPlaying">
    <SoundTrack :title="title" :album="album" :artist="artists" :image="image" :color1="bgColor1" :color2="bgColor2"
      :remaining="remaining" :duration="duration" :loaded="loaded" :klass="klass" />
  </section>
  <section class="currentMenu">{{ images }}
    <div class="oysterAvailability">
      <header>
        <h1 class="withAccent">Daily Oysters</h1>
      </header>
      <div class="oysterLists">
        <h3>West Coast</h3>

        <div class="oysterList">
          <ul v-if="dataLoaded">
            <li v-for="west in westCoast" :class="[{ 'active': west.available }]"
              v-html="buildOysterTitle(west.name, west.location)"></li>
          </ul>
        </div>

        <h3>East Coast</h3>

        <div class="oysterList eastCoast">
          <ul v-if="dataLoaded">
            <li v-for="east in eastCoast" :class="[{ 'active': east.available }]"
              v-html="buildOysterTitle(east.name, east.location)"></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="seafoodAvailability">
      <header>
        <h1 class="withAccent">Mkt Price</h1>
      </header>
      <div class="">
        <h3>Seafood</h3>

        <ul class="seafoodOptions" v-if="seafood">
          <li v-for="sea in seafood" :class="[{ 'withPrice': true, 'active': sea.available }]"><span>{{ sea.name
          }}</span><span>{{ sea.price }}</span></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'pinia';

import { createClient } from 'graphql-ws';
import { WebSocket } from 'ws';

import { dataStore } from '../stores/data.js';
import { soundStore } from '../stores/sound.js';

import SoundTrack from '../components/SoundTrack.vue';

export default {
  name: "OysterApp",
  components: {
    SoundTrack
  },
  mounted() {
    const ds = dataStore();
    const ss = soundStore();

    ss.loadCurrentSong();
    ds.loadData().then(() => {

      if (this.dataLoaded) {
        const client = createClient({
          webSocketImpl: WebSocket,
          url: 'wss://api.soundtrackyourbrand.com/v2/graphql-transport-ws',
          retryAttempts: Infinity,
          shouldRetry: () => true,
          keepAlive: 10000,
          connectionParams: {
            headers: {
              
            }
          },
          on: {
            connected: (...args) => {
              console.log('ON CONNECTED:', args);
            },
            connecting: (...args) => {
              console.log('CONNECTING:', args);
            },
            opened: (...args) => {
              console.log('OPENED:', args);
            },
            close: (...args) => {
              console.log('CLOSING:', args);
            },
            error: (...args) => {
              console.log('ERROR:', args);
            }
          }
        });

        (async () => {
          try {
            const subscription = client.iterate({
              query: `subscription nowPlaying { nowPlayingUpdate(input: {soundZone: \"${this.soundZone}\"}) { nowPlaying { track { name durationMs duration album { albumType colors { accent primary } images { url } artists { name } name } } startedAt } }}`,
            });

            for await (const event of subscription) {
              console.log('SUBSCRIPTION EVENT:', event);
              const ss = soundStore();
              ss.loadCurrentSong();
            }
          } catch (e) {
            console.log(e)
          }
        })();
      }
    });
  },
  data() {
    return {};
  },
  sockets: {
    connect() {
      //console.log('Vue socket open');
    },
    catalogUpdated(data) {
      console.log('NEW CATALOG DATA:', data)
      const ds = dataStore();
      ds.loadData();
    }
  },
  computed: {
    ...mapState(soundStore, {
      title: store => store.song.title,
      album: store => store.song.album,
      artists: store => store.song.artists,
      image: store => store.song.image,
      bgColor1: store => store.song.bgColor1,
      bgColor2: store => store.song.bgColor2,
      duration: store => store.song.duration,
      remaining: store => store.song.remainingTimeMs,
      loaded: store => store.loaded,
      klass: store => store.klass,
    }),
    ...mapState(dataStore, {
      dataLoaded: store => store.loaded,
      eastCoast: store => store.data.eastCoast,
      westCoast: store => store.data.westCoast,
      seafood: store => store.data.seafood,
      slotel: store => store.data.slotel,
      soundZone: store => store.data.soundzone
    })
  },
  methods: {
    buildOysterTitle: function (name, loc) {
      return `${name}, <span>${loc}<span>`
    },
    rotateSong: function (arr) {
      const ss = soundStore();
      ss.loadCurrentSong();
    }
  },
};
</script>

<style lang="scss" scoped></style>
