import { defineStore } from 'pinia';
import axios from 'Axios';

export const dataStore = defineStore('dataStore', {
    state: () => {
        return {
            loaded: false,
            data: {}
        }
    },
    actions: {
        loadData: async function () {            
            return await axios.post('../../../../loadData.php').then((resp) => {
                if(resp.status){
                    this.data = resp.data;
                    this.loaded = true;
                }
            })
        }
    }
});